import axios from "axios";

export default {
    async getEvents(userId: string) {
        const data = new FormData();
        data.append("where[events_user]", userId);
        data.append("orderby", "events_date_start");
        data.append("orderdir", "desc");

        //const response = await axios.post(`http://192.168.11.141/firegui_gmsystem/rest/v1/search/events`, data, {
        const response = await axios.post(`rest/v1/search/events`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },

    async deleteEvent(event_id: string) {
        //const response = await axios.get(`http://192.168.11.141/firegui_gmsystem/rest/v1/delete/events/${event_id}`, {
        const response = await axios.get(`rest/v1/delete/events/${event_id}`, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },
};
